import React, { useEffect } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel';

import '../ImageSlider/ImageSlider.css';

function ImageSlider() {
    useEffect(() => {
        /*********************
          Thumbnails slider
        *********************/
        // Change the main image whenever a thumbnail button is activated
        $('.thumbnails-slider').on('init', function (e, slider) {
            $(slider.$slides.find('.thumbnail-button')).each(function (index) {
                $(this).on('click', function () {
                    // Move aria-current="true" to this button
                    $(slider.$slides.find('.thumbnail-button').removeAttr('aria-current'));
                    $(this).attr('aria-current', true);

                    // Change the main image to match this thumbnail button
                    var index = $(this).closest('.slick-slide').data('slick-index');
                    $('.main-image-slider').slick('slickGoTo', index);
                });
            });
        });

        // Initialize the slider
        $('.thumbnails-slider').slick({
            vertical: true,
            slidesToShow: 4,
            infinite: false,
            arrows: false,
            instructionsText: 'This carousel contains a column of small thumbnails. Selecting a thumbnail will change the main image in the carousel that follows. Use the buttons to cycle through all the thumbnails, use Enter to select.',
            regionLabel: 'thumbnails carousel'
        });

        /********************
          Main image slider
        *********************/
        $('.main-image-slider').slick({
            slidesToShow: 1,
            draggable: false,
            arrows: true,
            instructionsText: 'This carousel shows one large product image at a time. Use the buttons to move between images, or use the preceding thumbnails carousel to select a specific image to display here.',
            regionLabel: 'main image carousel',
            width: '600px'
        });

        // Update the thumbnail slider when the user changes the main slider directly.
        $('.main-image-slider').on('beforeChange', function (e, slider, currentSlide, nextSlide) {
            // Remove aria-current from the last selected thumbnail image button
            $('.thumbnails-slider .thumbnail-button[aria-current="true"]').removeAttr('aria-current');

            // Select the thumbnail image button that goes with this main image. Most importantly, this updates Slick's internal state to be consistent with the visual change.
            $('.thumbnails-slider').slick('slickGoTo', nextSlide);

            // Add aria-current="true" to the correct thumbnail image button to convey to screen readers that it's active.
            $('.thumbnails-slider .thumbnail-button:eq(' + nextSlide + ')').attr('aria-current', true);
        });
    }, []);

    return (
        <>
            <div className="product-image-carousels">
                {/* <!-- Thumbnail track carousel --> */}
                <div className="thumbnails-slider">
                    <button className="thumbnail-button" aria-current="true">
                        {/* <!-- Note that the alt attribute values describe the FUNCTION of the button, not the CONTENT of the image inside the button. --> */}
                        <img src="/1.JPG" alt="Go to image 1" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/2.jpg" alt="Go to image 2" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/3.jpg" alt="Go to image 3" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/4.jpg" alt="Go to image 4" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/5.jpg" alt="Go to image 5" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/6.jpg" alt="Go to image 6" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/7.jpg" alt="Go to image 7" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/8.jpg" alt="Go to image 8" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/9.jpg" alt="Go to image 9" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/10.jpg" alt="Go to image 10" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/11.jpg" alt="Go to image 11" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/12.jpg" alt="Go to image 12" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/13.jpg" alt="Go to image 13" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/14.jpg" alt="Go to image 14" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/15.jpg" alt="Go to image 15" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/16.jpg" alt="Go to image 16" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/17.jpg" alt="Go to image 17" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/18.jpg" alt="Go to image 18" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/19.jpg" alt="Go to image 19" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/20.jpg" alt="Go to image 20" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/21.jpg" alt="Go to image 21" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/22.jpg" alt="Go to image 22" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/23.jpg" alt="Go to image 23" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/24.jpg" alt="Go to image 24" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/25.jpg" alt="Go to image 25" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/26.jpg" alt="Go to image 26" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/27.jpg" alt="Go to image 27" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/28.jpg" alt="Go to image 28" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/29.jpg" alt="Go to image 29" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/30.jpg" alt="Go to image 30" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/31.jpg" alt="Go to image 31" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/32.jpg" alt="Go to image 32" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/33.jpg" alt="Go to image 33" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/34.jpg" alt="Go to image 34" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/35.jpg" alt="Go to image 35" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/36.jpg" alt="Go to image 36" />
                    </button>

                    <button className="thumbnail-button">
                        <img src="/37.jpg" alt="Go to image 37" />
                    </button>
                </div>

                {/* <!-- Main image carousel --> */}
                <div className="main-image-slider">
                    <a href="/1.JPG" target="_blank" className="image-link firstpic">
                        <img src="/1.JPG" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/2.jpg" target="_blank" className="image-link">
                        <img src="/2.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/3.jpg" target="_blank" className="image-link">
                        <img src="/3.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/4.jpg" target="_blank" className="image-link">
                        <img src="/4.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/5.jpg" target="_blank" className="image-link">
                        <img src="/5.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/6.jpg" target="_blank" className="image-link">
                        <img src="/6.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/7.jpg" target="_blank" className="image-link">
                        <img src="/7.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/8.jpg" target="_blank" className="image-link">
                        <img src="/8.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/9.jpg" target="_blank" className="image-link">
                        <img src="/9.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/10.jpg" target="_blank" className="image-link">
                        <img src="/10.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/11.jpg" target="_blank" className="image-link">
                        <img src="/11.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/12.jpg" target="_blank" className="image-link">
                        <img src="/12.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/13.jpg" target="_blank" className="image-link">
                        <img src="/13.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/14.jpg" target="_blank" className="image-link">
                        <img src="/14.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/15.jpg" target="_blank" className="image-link">
                        <img src="/15.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/16.jpg" target="_blank" className="image-link">
                        <img src="/16.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/17.jpg" target="_blank" className="image-link">
                        <img src="/17.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/18.jpg" target="_blank" className="image-link">
                        <img src="/18.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/19.jpg" target="_blank" className="image-link">
                        <img src="/19.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/20.jpg" target="_blank" className="image-link">
                        <img src="/20.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/21.jpg" target="_blank" className="image-link">
                        <img src="/21.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/22.jpg" target="_blank" className="image-link">
                        <img src="/22.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/23.jpg" target="_blank" className="image-link">
                        <img src="/23.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/24.jpg" target="_blank" className="image-link">
                        <img src="/24.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/25.jpg" target="_blank" className="image-link">
                        <img src="/25.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/26.jpg" target="_blank" className="image-link">
                        <img src="/26.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/27.jpg" target="_blank" className="image-link">
                        <img src="/27.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/28.jpg" target="_blank" className="image-link">
                        <img src="/28.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/29.jpg" target="_blank" className="image-link">
                        <img src="/29.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/30.jpg" target="_blank" className="image-link">
                        <img src="/30.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/31.jpg" target="_blank" className="image-link">
                        <img src="/31.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/32.jpg" target="_blank" className="image-link">
                        <img src="/32.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/33.jpg" target="_blank" className="image-link">
                        <img src="/33.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/34.jpg" target="_blank" className="image-link">
                        <img src="/34.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/35.jpg" target="_blank" className="image-link">
                        <img src="/35.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/36.jpg" target="_blank" className="image-link">
                        <img src="/36.jpg" alt="6836 Troy Lane N" />
                    </a>

                    <a href="/37.jpg" target="_blank" className="image-link">
                        <img src="/37.jpg" alt="6836 Troy Lane N" />
                    </a>
                </div>
            </div>
        </>
    );
}

export default ImageSlider