import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaHandshake, FaCalendar, FaChartLine, FaPercent, FaMoneyBill, FaDollarSign, FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaEnvelope } from 'react-icons/fa';
import "../Home/Home.css";
import HeroSection from '../../components/HeroSection/HeroSection';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import Slideshow from '../../components/Slideshow/Slideshow';
import Testimonials from '../../components/Testimonials/Testimonials';

function Home() {
    const handleCall = () => {
        window.location.href = "tel: +19528482475";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nreteam@nevinrealestate.com";
    };

    return (
        <>
            <HeroSection />
            <div className="sold-pages-ath-container">
                <div className="spath-container" id="about-the-home">
                    <p className="sold-pages-just-sold-headline">Just Listed</p>
                </div>
                <div className="home-about-container">
                    <div className="home-about-img-wrapper">
                        <div className="image-slider">
                            <ImageSlider />
                        </div>
                        <img src="/1.JPG" className="home-about-img" alt="6836 Troy Lane N" />
                    </div>
                    <div className="home-about-text-div">
                        <p className="home-about-h1">6836 Troy Lane N</p>
                        <h3 className="home-about-h3">4 bed | 4 Bath | 3,768 SF</h3>
                        <div className="home-about-p-div">
                            <div className="home-about-ul-div">
                                <ul>
                                    <li className="home-about-p">
                                        Single Family Home
                                    </li>
                                    <li className="home-about-p">
                                        4 Beds & 4 Baths
                                    </li>
                                    <li className="home-about-p">
                                        Well Maintained
                                    </li>
                                    <li className="home-about-p">
                                        Walkout Lower Level
                                    </li>
                                </ul>
                            </div>
                            <div className="home-about-ul-div">
                                <ul>
                                    <li className="home-about-p">
                                        Upper-Level Laundry
                                    </li>
                                    <li className="home-about-p">
                                        Basketball Court
                                    </li>
                                    <li className="home-about-p">
                                        In-Demand Neighborhood
                                    </li>
                                    <li className="home-about-p">
                                        Listed at $151.25/Sq. Ft.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p className="home-about-p">
                            Call us to discuss our pricing strategy and recommendations. This market is different than last year's and to be successful a new pricing strategy must be adopted. With the help of our seller, we have mapped out a plan to create a successful transaction.
                        </p>
                        <div className="home-about-contact-info">
                            <div className="home-about-contact-info-phone-license">
                                <div className="hacipl-left">
                                    <p className="home-about-label">
                                        Priced at $569,900
                                    </p>
                                </div>
                                <div className="hacipl-right">
                                    <p className="home-about-label">
                                        Won't Last Long
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mtt-button-wrapper">
                            <a href="https://nevinraghuveer.results.net/real-estate/6836-troy-lane-n-maple-grove-mn-55311/546807044/" className="mtt-button listing-button" target="_blank">View the Listing</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="market-comparison-lg">
                <div className="mc-outer-container" id="sold">
                    <p className="headline-h1">Market Comparison</p>
                    <div className="mc-table-wrapper">
                        <table className="mc-comparison-table">
                            <thead className="mc-table-header">
                                <th className="mc-table-header-cell topleft"></th>
                                <th className="mc-table-header-cell nretablecell">The NRE Team</th>
                                <th className="mc-table-header-cell topright">Our Competitors</th>
                            </thead>
                            <tbody className="mc-table-body">
                                <tr className="mc-table-body-row">
                                    <td className="mc-table-data-cell data-label">Days on Market<br /><span className="mc-table-data-source">Average &mdash; 2024 YTD</span></td>
                                    <td className="mc-table-data-cell data nredata">4</td>
                                    <td className="mc-table-data-cell reddata">35</td>
                                </tr>
                                <tr className="mc-table-body-row">
                                    <td className="mc-table-data-cell data-label">List-to-Sale Price Ratio<br /><span className="mc-table-data-source">Median &mdash; 2024 YTD</span></td>
                                    <td className="mc-table-data-cell data nredata">101.75%</td>
                                    <td className="mc-table-data-cell data reddata">99.5%</td>
                                </tr>
                                <tr className="mc-table-body-row">
                                    <td className="mc-table-data-cell data-label bottomleft">Difference in Sale Price<br /><span className="mc-table-data-source">Based on $800,000 List Price</span></td>
                                    <td className="mc-table-data-cell data nredata">+ $18,000</td>
                                    <td className="mc-table-data-cell data reddata bottomright">- $4,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="mc-source">Source: NorthstarMLS</p>
                </div>
            </div>
            {/* <div className="market-comparison">
                <div className="mc-outer-container" id="sold">
                    <p className="headline-h1">Market Comparison</p>
                    <div className="mc-container">
                        <div className="mc-left">
                            <p className="mc-name">
                                Nevin
                            </p>
                            <div className="mc-fa-circle">
                                <FaHandshake className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                4
                            </p>
                            <p className="mc-stat-2">
                                Days on Market
                            </p>
                            <p className="mc-source">
                                Median - 2024 YTD
                            </p>
                            <div className="mc-divider"></div>
                            <p className="mc-name">
                                Market
                            </p>
                            <div className="mc-fa-circle">
                                <FaCalendar className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                35
                            </p>
                            <p className="mc-stat-2">
                                Days on Market
                            </p>
                            <p className="mc-source">
                                Median - 2024 YTD
                            </p>
                            <div className="mc-divider-sm"></div>
                        </div>
                        <div className="mc-middle">
                            <p className="mc-name">
                                Nevin
                            </p>
                            <div className="mc-fa-circle">
                                <FaChartLine className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                101.75%
                            </p>
                            <p className="mc-stat-2">
                                List-to-Sale Price Ratio
                            </p>
                            <p className="mc-source">
                                Average - 2024 YTD
                            </p>
                            <div className="mc-divider"></div>
                            <p className="mc-name">
                                Market
                            </p>
                            <div className="mc-fa-circle">
                                <FaPercent className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                99.5%
                            </p>
                            <p className="mc-stat-2">
                                List-to-Sale Price Ratio
                            </p>
                            <p className="mc-source">
                                Average - 2024 YTD
                            </p>
                            <div className="mc-divider-sm"></div>
                        </div>
                        <div className="mc-right">
                            <p className="mc-name">
                                Nevin
                            </p>
                            <div className="mc-fa-circle">
                                <FaMoneyBill className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                $18,000
                            </p>
                            <p className="mc-stat-2">
                                Higher Sale Price
                            </p>
                            <p className="mc-source">
                                Based on $800,000 List Price
                            </p>
                            <div className="mc-divider"></div>
                            <p className="mc-name">
                                Market
                            </p>
                            <div className="mc-fa-circle">
                                <FaDollarSign className="mc-fa" />
                            </div>
                            <p className="mc-stat">
                                -$4,000
                            </p>
                            <p className="mc-stat-2">
                                Average Price Change
                            </p>
                            <p className="mc-source">
                                Based on $800,000 List Price
                            </p>
                        </div>
                    </div>
                    <p className="mc-source source-bottom">
                        Source: NorthstarMLS
                    </p>
                </div>
            </div>
            <div className="market-comparison-lg">
                <div className="mc-outer-container" id="sold">
                    <p className="headline-h1">Market Comparison</p>
                    <div className="mc-container-lg-top">
                        <div className="mc-container-lg-name-div">
                            <p className="mc-name-lg">
                                Nevin Real Estate's Stats
                            </p>
                        </div>
                        <div className="mc-container-lg-sections">
                            <div className="mc-lg-nre-left">
                                <div className="mc-fa-circle">
                                    <FaHandshake className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    4
                                </p>
                                <p className="mc-stat-2">
                                    Days on Market
                                </p>
                                <p className="mc-source">
                                    Median - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-nre-middle">
                                <div className="mc-fa-circle">
                                    <FaChartLine className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    101.75%
                                </p>
                                <p className="mc-stat-2">
                                    List-to-Sale Price Ratio
                                </p>
                                <p className="mc-source">
                                    Average - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-nre-right">
                                <div className="mc-fa-circle">
                                    <FaMoneyBill className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    $18,000
                                </p>
                                <p className="mc-stat-2">
                                    Higher Sale Price
                                </p>
                                <p className="mc-source">
                                    Based on $800,000 List Price
                                </p>
                            </div>
                        </div>
                        <div className="mc-divider-lg"></div>
                    </div>
                    <div className="mc-container-lg-bottom">
                        <div className="mc-container-lg-name-div">
                            <p className="mc-name-lg">
                                Local Market Stats
                            </p>
                        </div>
                        <div className="mc-container-lg-sections">
                            <div className="mc-lg-lm-left">
                                <div className="mc-fa-circle">
                                    <FaCalendar className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    35
                                </p>
                                <p className="mc-stat-2">
                                    Days on Market
                                </p>
                                <p className="mc-source">
                                    Median - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-lm-middle">
                                <div className="mc-fa-circle">
                                    <FaPercent className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    99.5%
                                </p>
                                <p className="mc-stat-2">
                                    List-to-Sale Price Ratio
                                </p>
                                <p className="mc-source">
                                    Average - 2024 YTD
                                </p>
                            </div>
                            <div className="mc-lg-lm-right">
                                <div className="mc-fa-circle">
                                    <FaDollarSign className="mc-fa" />
                                </div>
                                <p className="mc-stat">
                                    -$4,000
                                </p>
                                <p className="mc-stat-2">
                                    Average Price Change
                                </p>
                                <p className="mc-source">
                                    Based on $800,000 List Price
                                </p>
                            </div>
                        </div>
                    </div>
                    <p className="mc-source source-bottom">
                        Source: NorthstarMLS
                    </p>
                </div>
            </div> */}
            <div className="other-sold-properties" id="sold">
                <div className="sp-sold-properties">
                    <p className="headline-h1 psh1">Our Sales in Gleason Farms</p>
                    <div className="sp-sold-properties-bottom">
                        <div className="sp-sold-properties-map-divs">
                            <div className="sold-property-img-wrapper">
                                <img src="/gleason-farms-sold-signs.png" alt="Previous Gleason Farms Sales by Nevin Real Estate" className="sp-sold-property-img" />
                            </div>
                            <div className="sp-sold-properties-map-right-div">
                                <p className="sp-sold-properties-headline-text">
                                    We Know Gleason Farms Inside and Out!
                                </p>
                                <p className="sp-map-blurb">
                                    We have been selling Gleason Farms for 20 years. From the development and floorplans to its amenities, such as the pool, clubhouse, parks and trails, no one knows your neighborhood better than we do. We will sell your home for more!
                                </p>
                                <Link to="http://www.nevinrealestate.com/contact" className="previous-sales-button">Schedule A Consultation</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-marketing" id="our-marketing">
                <p className="headline-h1">Selling with Nevin Real Estate</p>
                <div className="om-main-container">
                    <div className="om-main-top">
                        <div className="om-main-top-left">
                            <img src="/marketing-platforms.png" alt="Market Analysis" className="om-main-top-img" />
                            <div className="om-img-overlay-top">
                                <p className="om-img-overlay-text">
                                    Multiplatform Marketing
                                </p>
                            </div>
                        </div>
                        <div className="om-main-top-right">
                            <p className="om-headline">
                                We are your competitive market advantage.
                            </p>
                            <p className="om-blurb">
                                We market each and every listing with the same level of strategy and attention. Our goal is to maximize your home's exposure to help attract the perfect buyer, using industry-leading print and digital marketing strategies.
                            </p>
                            <a href="http://www.nevinrealestate.com/contact" target="_blank" className="om-button">
                                Schedule a Consultation
                            </a>
                        </div>
                    </div>
                    <div className="om-main-bottom">
                        <div className="om-main-bottom-left">
                            <p className="om-headline">
                                Our Services
                            </p>
                            <div className="om-os-row">
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Expert Market Analysis
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Complimentary Staging
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Professional Photography
                                    </p>
                                </div>
                            </div>
                            <div className="om-os-row">
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Custom Property Website
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        3D Virtual Tour
                                    </p>
                                </div>
                                <div className="om-os-div">
                                    <p className="om-blurb">
                                        Multiplatform Marketing
                                    </p>
                                </div>
                            </div>
                            <a href="http://www.nevinrealestate.com/contact" target="_blank" className="om-button">
                                Get Started
                            </a>
                        </div>
                        <div className="om-main-bottom-right">
                            <img src="/home-staging.jpg" alt="Home Staging" className="om-main-bottom-img" />
                            <div className="om-img-overlay bottom-overlay">
                                <p className="om-img-overlay-text">
                                    Home Staging
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="video-div">
                        <video src="/gleason-video.mp4" className="video" alt="Video" controls />
                        <div className="video-div-right">
                            <p className="sp-sold-properties-headline-text indent">
                                Your Local Gleason Farms Experts
                            </p>
                            <p className="video-blurb">
                                The Nevin Real Estate team will sell your home for more, in less time than our competitors - all while providing you with an unmatched level of service. We guarantee it!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Slideshow /> */}
            <div className="sold-pages-meet-the-team" id="meettheteam">
                <h1 className="mtt-headline-h1">The Nevin Real Estate Team</h1>
                <div className="sp-mtt-bio-container">
                    <div className="sp-mtt-bio-div">
                        <p className="sp-mtt-bio-p">
                            Whether you're a first-time homebuyer or an experienced investor, our team has proven, successful methods to make your home buying or selling process smooth and stress-free. We will work closely with you to understand your needs and to help you achieve your real estate goals.</p>
                        <p className="sp-mtt-bio-p">
                            Our approach works. Our clients consistently outperform the market, and Nevin's success in real estate continues to earn him several prestigious awards and designations. Combined, our team has over 35 years of real estate experience and more than 1,000 homes sold.
                        </p>
                    </div>
                </div>
                <div className="mtt-left-and-right-divs">
                    <div className="sp-meet-team-links-div">
                        <div className="sp-meet-team-link-wrapper">
                            <div className="sp-meet-team-img-wrapper">
                                <img src="/nevin.png" className="sp-meet-team-img" />
                            </div>
                            <div className="mtt-text-div">
                                <p className="mtt-name">Nevin Raghuveer</p>
                                <p className="mtt-title">REALTOR &reg; | Founder</p>
                                <p className="mtt-bio">
                                    Nevin has over 25 years of experience and consistently ranks as one of the top agents nationwide. His sharp market insight, strong negotiation skills, and dedication to customer service ensure you have a smooth and rewarding home sale.
                                </p>
                                <div className="mtt-button-wrapper">
                                    <Link to="http://www.nevinrealestate.com/about-nevin" className="mtt-button">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="sp-meet-team-link-wrapper">
                            <div className="sp-meet-team-img-wrapper">
                                <img src="/elizabeth.png" className="sp-meet-team-img" alt="Elizabeth Mullen" />
                            </div>
                            <div className="mtt-text-div">
                                <p className="mtt-name">Elizabeth Mullen</p>
                                <p className="mtt-title">REALTOR &reg; | Operations Manager</p>
                                <p className="mtt-bio">
                                    With over 15 years of experience in real estate and expertise in all areas of project management to prepare listings for sale, Elizabeth does what it takes to make sure you get the best deal possible.
                                </p>
                                <div className="mtt-button-wrapper">
                                    <Link to="http://www.nevinrealestate.com/about-elizabeth" className="mtt-button">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="sp-meet-team-link-wrapper">
                            <div className="sp-meet-team-img-wrapper">
                                <img src="/cassandra.png" className="sp-meet-team-img" alt="Cassandra Bryan" />
                            </div>
                            <div className="mtt-text-div">
                                <p className="mtt-name">Cassandra Bryan</p>
                                <p className="mtt-title">REALTOR &reg; | Marketing Manager</p>
                                <p className="mtt-bio">
                                    Cassandra brings an eye for detail and in-depth tech knowledge to the table. She will market your property via traditional and cutting-edge methods, from data-driven print and digital campaigns to full stack development of custom property websites.
                                </p>
                                <div className="mtt-button-wrapper">
                                    <Link to="http://www.nevinrealestate.com/about-cassandra" className="mtt-button">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mtt-awards-div">
                        <p className="sold-pages-h2">AWARDS</p>
                        <div className="sp-mtt-awards-container">
                            <div className="sp-mtt-awards-img-wrap"><img src="/hof.jpg" alt="Hall of Fame" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/mpls-st-paul.jpg" alt="Minneapolis-St. Paul Magazine SuperAgent" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/zillow-awards.jpg" alt="Zillow Awards" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/lifetime.jpg" alt="Lifetime Achievement Award" className="sp-mtt-awards-img" /></div>
                            <div className="sp-mtt-awards-img-wrap"><img src="/real-trends-1.jpg" alt="America's Best Real Estate Agents - Real Trends" className="sp-mtt-awards-img" /></div>
                        </div>
                        <div className="sp-mtt-awards-text-div">
                            <p className="sp-mtt-awards-text">
                                #1 RE/MAX Results Agent
                            </p>
                            <p className="sp-mtt-awards-text">
                                #2 RE/MAX Agent in the Midwest
                            </p>
                            <p className="sp-mtt-awards-text">
                                #49 RE/MAX Agent in the Country
                            </p>
                            <p className="sp-mtt-awards-text">
                                #1 RE/MAX Luxury Agent in Minnesota
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonials />
            <div className="sold-pages-parallax" id="contact">
                <div className="sold-pages-parallax-content">
                    <h1 className="sold-pages-parallax-headline">Work With Nevin Real Estate</h1>
                    <a href="http://www.nevinrealestate.com/seller-questionnaire" className="sold-pages-parallax-button">Seller Questionnaire</a>
                    <a href="http://www.nevinrealestate.com/contact" className="sold-pages-parallax-button">Schedule A Consultation</a>
                </div>
            </div>
            <div className="sp-footer-container">
                <div className="sp-footer-logo-wrapper">
                    <img src="/white-side-by-side-logo.png" className="sp-footer-logo" alt="Nevin Real Estate Logo" />
                </div>
                <div className="sp-footer-site-link-div">
                    <a href="#top" className="sp-footer-link">Top</a>
                    <a href="#about-the-home" className="sp-footer-link">6836 Troy Lane North</a>
                    <a href="https://consumer.hifello.com/p/nevinrealestate/6682c3eda55064002ebaf538" className="sp-footer-link" target="_blank" rel="noopener noreferrer">Home Valuation</a>
                    <a href="#our-marketing" className="sp-footer-link">Our Sales Process</a>
                    <a href="#sold" className="sp-footer-link">Market Comparison</a>
                    <a href="#meettheteam" className="sp-footer-link">About Us</a>
                    <a href="http://www.nevinrealestate.com/contact" className="sp-footer-link" target="_blank" rel="noopener noreferrer">Contact</a>
                </div>
                <div className="sp-footer-contact-address-div">
                    <div className="sp-footer-contact-div">
                        <p className="sp-footer-label">Contact</p>
                        <p className="sp-footer-info-bold">Nevin Raghuveer</p>
                        <p className="sp-footer-info">(952) 848-2475</p>
                        <p className="sp-footer-info">NRETeam@<br className="footer-br" />NevinRealEstate.com</p>
                    </div>
                    <div className="sp-footer-address-div">
                        <p className="sp-footer-label">Address</p>
                        <p className="sp-footer-info">3535 Plymouth Boulevard, Suite 210</p>
                        <p className="sp-footer-info">Plymouth, MN 55447</p>
                    </div>
                </div>
                <div className="sp-footer-social-links-div">
                    <div className="sp-footer-social-links">
                        <div className="sp-footer-social-wrapper">
                            <a href="http://www.facebook.com/nevinrealestate" className="sp-footer-social-link" target="_blank" rel="noopener noreferrer">
                                <FaFacebook className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper">
                            <a href="http://www.instagram.com/nevinrealestate" className="sp-footer-social-link" target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper">
                            <a href="https://www.linkedin.com/in/nevinraghuveer" className="sp-footer-social-link" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper" onClick={handleCall}>
                            <FaPhone className="sp-footer-social-icon" />
                        </div>
                        <div className="sp-footer-social-wrapper" onClick={handleEmail}>
                            <FaEnvelope className="sp-footer-social-icon" />
                        </div>
                    </div>
                </div>
                <div className="sp-footer-legal-and-credentials-div">
                    <div className="sp-footer-credentials-div">
                        <div className="sp-footer-compliance-logos-div">
                            <img src="/white-fair-housing.png" className="sp-footer-compliance-logos" alt="REALTOR, MLS, and Equal Housing Logos" />
                        </div>
                    </div>
                    <div className="sp-footer-legal-div">
                        <p className="sp-footer-legal-p">&copy; 2024 Nevin Real Estate. | <Link to="/privacy" className="footer-privacy-link">Privacy Policy</Link></p>
                        <p className="sp-footer-legal-p disclaimer">All information is deemed reliable but not guaranteed and should be independently reviewed and verified.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
