import React, { useState, useEffect } from 'react';
import "../PrivacyPolicy/PrivacyPolicy.css";
import { Link } from 'react-router-dom';
import { FaPhone, FaChevronCircleLeft, FaChevronCircleRight, FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaPercent, FaCalendar, FaDollarSign, FaMoneyBillTrendUp, FaChartLine, FaHandshake, FaMoneyBill } from "react-icons/fa";

function PrivacyPolicy() {
    const handleCall = () => {
        window.location.href = "tel: +19528482475";
    };

    const handleEmail = () => {
        window.location.href = "mailto:nreteam@nevinrealestate.com";
    };

    return (
        <>
            <div className="pp-page-container">
                <h1 className="pp-headline-h1">
                    Privacy Policy
                </h1>
                <h2 className="pp-headline-h2">
                    True North Real Estate d/b/a Nevin Real Estate Team with Remax Results
                </h2>
                <h2 className="pp-headline-h2">
                    Effective Date: July 22, 2024
                </h2>
                <p className="pp-paragraph">
                    True North Real Estate, doing business as Nevin Real Estate Team with Remax Results, is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website.
                </p>
                <p className="pp-paragraph-bold">
                    1. Information We Collect
                </p>
                <p className="pp-paragraph">
                    We collect information to understand your needs and provide you with better service. The information we collect includes:
                </p>
                <ul className="pp-list">
                    <li className="pp-list-li">Your IP address</li>
                    <li className="pp-list-li">The date and time of your visit</li>
                    <li className="pp-list-li">Any code provided as part of the URL</li>
                </ul>
                <p className="pp-paragraph">
                    This information is collected for tracking purposes to help us understand how our website is being used.
                </p>
                <p className="pp-paragraph-bold">
                    2. Use of Collected Information
                </p>
                <p className="pp-paragraph">
                    The information we collect is used to:
                </p>
                <ul className="pp-list">
                    <li className="pp-list-li">Monitor and analyze website usage to improve our services</li>
                    <li className="pp-list-li">Ensure the security and proper functioning of our website</li>
                </ul>
                <p className="pp-paragraph-bold">
                    3. Data Sharing and Selling
                </p>
                <p className="pp-paragraph">
                    We do not share, sell, or distribute your personal information to third parties. The data collected (your IP address, visit date and time, and URL code) is solely for our internal use to enhance your experience on our website.
                </p>
                <p className="pp-paragraph-bold">
                    4. Data Security
                </p>
                <p className="pp-paragraph">
                    We are committed to ensuring that your information is secure. We have implemented suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.
                </p>
                <p className="pp-paragraph-bold">
                    5. Changes to this Privacy Policy
                </p>
                <p className="pp-paragraph">
                    True North Real Estate d/b/a Nevin Real Estate Team with Remax Results may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </p>
                <p className="pp-paragraph-bold">
                    6. Contact Us
                </p>
                <p className="pp-paragraph">
                    If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <p className="pp-paragraph-bold ppci">
                    True North Real Estate d/b/a Nevin Real Estate Team with Remax Results
                </p>
                <p className="pp-paragraph-bold ppci">
                    3535 Plymouth Blvd., Ste. 210
                </p>
                <p className="pp-paragraph-bold ppci">
                    Plymouth, MN 55447
                </p>
                <p className="pp-paragraph-bold ppci">
                    Web Administrator: Cassandra Bryan
                </p>
                <p className="pp-paragraph-bold ppci">
                    Email: Cassandra@NevinRealEstate.com
                </p>
                <p className="pp-paragraph-bold ppci">
                    Phone: (952) 848-2538
                </p>
                <p className="pp-paragraph pplp">
                    By using our website, you consent to the collection and use of information as described in this Privacy Policy.
                </p>
                <Link to="/" className="pp-home-link">
                    Return to Home Page
                </Link>
            </div>
            <div className="sp-footer-container">
                <div className="sp-footer-logo-wrapper">
                    <img src="/white-side-by-side-logo.png" className="sp-footer-logo" alt="Nevin Real Estate Logo" />
                </div>
                <div className="sp-footer-site-link-div">
                    <a href="#top" className="sp-footer-link">Top</a>
                    <a href="#about-the-home" className="sp-footer-link">6836 Troy Lane North</a>
                    <a href="https://consumer.hifello.com/p/nevinrealestate/6682c3eda55064002ebaf538" className="sp-footer-link" target="_blank" rel="noopener noreferrer">Home Valuation</a>
                    <a href="#our-marketing" className="sp-footer-link">Our Sales Process</a>
                    <a href="#sold" className="sp-footer-link">Market Comparison</a>
                    <a href="#meettheteam" className="sp-footer-link">About Us</a>
                    <a href="http://www.nevinrealestate.com/contact" className="sp-footer-link" target="_blank" rel="noopener noreferrer">Contact</a>
                </div>
                <div className="sp-footer-contact-address-div">
                    <div className="sp-footer-contact-div">
                        <p className="sp-footer-label">Contact</p>
                        <p className="sp-footer-info-bold">Nevin Raghuveer</p>
                        <p className="sp-footer-info">(952) 848-2475</p>
                        <p className="sp-footer-info">NRETeam@<br className="footer-br" />NevinRealEstate.com</p>
                    </div>
                    <div className="sp-footer-address-div">
                        <p className="sp-footer-label">Address</p>
                        <p className="sp-footer-info">3535 Plymouth Boulevard, Suite 210</p>
                        <p className="sp-footer-info">Plymouth, MN 55447</p>
                    </div>
                </div>
                <div className="sp-footer-social-links-div">
                    <div className="sp-footer-social-links">
                        <div className="sp-footer-social-wrapper">
                            <a href="http://www.facebook.com/nevinrealestate" className="sp-footer-social-link" target="_blank" rel="noopener noreferrer">
                                <FaFacebook className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper">
                            <a href="http://www.instagram.com/nevinrealestate" className="sp-footer-social-link" target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper">
                            <a href="https://www.linkedin.com/in/nevinraghuveer" className="sp-footer-social-link" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin className="sp-footer-social-icon" />
                            </a>
                        </div>
                        <div className="sp-footer-social-wrapper" onClick={handleCall}>
                            <FaPhone className="sp-footer-social-icon" />
                        </div>
                        <div className="sp-footer-social-wrapper" onClick={handleEmail}>
                            <FaEnvelope className="sp-footer-social-icon" />
                        </div>
                    </div>
                </div>
                <div className="sp-footer-legal-and-credentials-div">
                    <div className="sp-footer-credentials-div">
                        <div className="sp-footer-compliance-logos-div">
                            <img src="/white-fair-housing.png" className="sp-footer-compliance-logos" alt="REALTOR, MLS, and Equal Housing Logos" />
                        </div>
                    </div>
                    <div className="sp-footer-legal-div">
                        <p className="sp-footer-legal-p">&copy; 2024 Nevin Real Estate. | <Link to="/privacy" className="footer-privacy-link">Privacy Policy</Link></p>
                        <p className="sp-footer-legal-p">All information is deemed reliable but not guaranteed and should be independently reviewed and verified.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
