import React, { useState, useEffect } from 'react';
import "../Slideshow/Slideshow.css";

const Slideshow = () => {
    const [activeID, setActiveID] = useState(0);
    const [buttonHover, setButtonHover] = useState(false);

    const data = [
        {
            id: 0,
            header: 'Slide 1 Headline Here',
            body: 'A description of Service 1 or Item 1 goes here.',
            color: '#243852',
            img: '/enclave.jpg'
        },
        {
            id: 1,
            header: 'Slide 2 Headline Here',
            body: 'A description of Service 1 or Item 1 goes here.',
            color: '#355682',
            img: '/georgetown-sign.png'
        },
        {
            id: 2,
            header: 'Slide 3 Headline Here',
            body: 'A description of Service 1 or Item 1 goes here.',
            color: '#243852',
            img: '/flagandtree.jpg'
        },
        {
            id: 3,
            header: 'Slide 4 Headline Here',
            body: 'A description of Service 1 or Item 1 goes here.',
            color: '#355682',
            img: '/luxury-interior-img.jpg'
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveID(prevID => (prevID + 1) % data.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [data.length]);

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    const wrapperStyle = {
        backgroundImage: `url('${data[activeID].img}')`
    };

    const panelStyle = {
        backgroundColor: data[activeID].color
    };

    const buttonStyle = {
        color: buttonHover ? data[activeID].color : '#ffffff'
    };

    const changeActive = (id) => {
        setActiveID(id);
    };

    const toggleButtonColor = () => {
        setButtonHover(!buttonHover);
    };

    return (
        <div className="wrapper-container">
            <section className="wrapper" style={wrapperStyle}>
                <Selectors data={data} activeID={activeID} changeActive={changeActive} />
                <Panel
                    data={data[activeID]}
                    panelStyle={panelStyle}
                    buttonStyle={buttonStyle}
                    toggleButtonColor={toggleButtonColor}
                />
            </section>
            <section className="main">
                <a className="arrow-wrap" href="#content">
                    <span className="arrow"></span>
                </a>
            </section>
        </div>
    );
};

const Panel = ({ data, panelStyle, buttonStyle, toggleButtonColor }) => {
    return (
        <aside className="panel" style={panelStyle}>
            <div className="panel-height">
                <h2 className="panel-header">{data.header}</h2>
                <p className="panel-info">{data.body}</p>
                <button
                    className="panel-button"
                    style={buttonStyle}
                    onMouseEnter={toggleButtonColor}
                    onMouseLeave={toggleButtonColor}
                >
                    Read More
                </button>
            </div>
        </aside>
    );
};

const Selectors = ({ data, activeID, changeActive }) => {
    return (
        <div className="selectors">
            {data.map((item) => (
                <Selector
                    key={item.id}
                    id={item.id}
                    changeActive={changeActive}
                    activeID={activeID}
                />
            ))}
        </div>
    );
};

const Selector = ({ id, changeActive, activeID }) => {
    const handleClick = () => {
        if (id !== activeID) {
            changeActive(id);
        }
    };

    let componentClass = 'selector';
    if (activeID === id) {
        componentClass = 'selector active';
    }

    return <div className={componentClass} onClick={handleClick} />;
};

export default Slideshow