import React, { useEffect } from 'react';
import $ from 'jquery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../HeroSection/HeroSection.css";

function HeroSection() {
    useEffect(() => {
        $(".hero-slider").slick({
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'linear',
            customPaging: function (i) {
                return `<div class="slick-dot"></div>`;
            },
            dotsClass: 'slick-dots slick-dots-custom',
        });

        $(window).scroll(function () {
            var Num = $(window).scrollTop() / 500;
            var Num2 = $(window).scrollTop() * 0.0004;
            var Num2mod = Num2 + 1;
            var Num3 = $(window).scrollTop() * 0.2;
            var Num3mod = Num3 + 1;
            $('.shade').css('opacity', Num);
            $(".bg").css({ "transform": "scale(" + Num2mod + ")" });
            $(".text").css({ "margin-top": "-" + Num3mod + "px" });
        });
    }, []);

    const scrollToSection = () => {
        const soldSection = document.getElementById('sold');
        if (soldSection) {
            soldSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="hero-slider">
            <div className="hero-slide" style={{ backgroundImage: "url('/georgetown-fountain.png')" }}>
                <div className="hero-content">
                    <div className="hero-text-div">
                        <h1 className="hero-headline n1gfa">#1 Gleason Farms Agent</h1>
                        <p className="hero-subheadline n1gfash">Selling Gleason Farms for Over 20 Years</p>
                        <div className="hero-buttons">
                            <button className="hero-button n1gfab" onClick={scrollToSection}>Learn More</button>
                            <a href="https://consumer.hifello.com/p/nevinrealestate/6682c3eda55064002ebaf538" target="_blank" rel="noopener noreferrer" className="hero-button">
                                What Is My Home Worth?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-slide" style={{ backgroundImage: "url('/georgetown-sign.png')" }}>
                <div className="hero-content">
                    <div className="hero-text-div">
                        <h1 className="hero-headline">Our Method Works</h1>
                        <p className="hero-subheadline">Over 100 Homes Solid in Gleason Farms</p>
                        <div className="hero-buttons">
                            <button className="hero-button" onClick={scrollToSection}>Learn More</button>
                            <a href="https://consumer.hifello.com/p/nevinrealestate/6682c3eda55064002ebaf538" target="_blank" rel="noopener noreferrer" className="hero-button">
                                What Is My Home Worth?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero-slide gfslide" style={{ backgroundImage: "url('/carriage-house-sign-2.jpg')" }}>
                <div className="hero-content">
                    <div className="hero-text-div gfhtd">
                        <h1 className="hero-headline">Your Local Experts</h1>
                        <p className="hero-subheadline">We Know Gleason Farms Inside and Out</p>
                        <div className="hero-buttons">
                            <button className="hero-button" onClick={scrollToSection}>Learn More</button>
                            <a href="https://consumer.hifello.com/p/nevinrealestate/6682c3eda55064002ebaf538" target="_blank" rel="noopener noreferrer" className="hero-button">
                                What Is My Home Worth?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="hero-slide" style={{ backgroundImage: "url('/gleason-farms-sign-5.jpg')" }}>
                <div className="heroEffects">
                    <div className="hero-content">
                        <div className="hero-text-div">
                            <h1 className="hero-headline">Your Local Experts</h1>
                            <p className="hero-subheadline">We Know Gleason Farms Inside and Out</p>
                            <div className="hero-buttons">
                                <button className="hero-button" onClick={scrollToSection}>Learn More</button>
                                <a href="https://www.google.com" target="_blank" rel="noopener noreferrer" className="hero-button">
                                    What Is My Home Worth?
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="hero-slide">
                <div className="heroEffects">
                    <div className="bg2"></div>
                    <div className="title centerV">
                        <div>
                            <div className="text">
                                <h1>Our Method Works</h1>
                                <p>Over 100 Homes Sold in Gleason Farms</p>
                            </div>
                        </div>
                    </div>
                    <div className="shade"></div>
                </div>
            </div>
            <div className="hero-slide">
                <div className="heroEffects">
                    <div className="bg3"></div>
                    <div className="title centerV">
                        <div>
                            <div className="text">
                                <h1>Your Gleason Farms Experts</h1>
                                <p>We know Gleason Farms Inside and Out</p>
                            </div>
                        </div>
                    </div>
                    <div className="shade"></div>
                </div>
            </div> */}
        </div>
    );
}

export default HeroSection;
