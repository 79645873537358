import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import HeroSection from './components/HeroSection/HeroSection';
import ScrollToTop from './components/ScrollToTop';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ImageSlider from './components/ImageSlider/ImageSlider';
import Slideshow from './components/Slideshow/Slideshow';
import Testimonials from './components/Testimonials/Testimonials';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hero-section" element={<HeroSection />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/image-slider" element={<ImageSlider />} />
          <Route path="/slideshow" element={<Slideshow />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
